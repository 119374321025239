.qmDialog .el-autocomplete {
  width: 100%;
}
.qmDialog .number-range {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid #DCDFE6;
}
.qmDialog .number-range .el-input__inner {
  border: 0 !important;
}
.qmDialog .form-box {
  background: #ffffff;
  padding: 12px;
}
.qmDialog .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.qmDialog .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.qmDialog .shuttleBackBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.qmDialog .shuttleBackBox .el-tabs {
  width: 100%;
}
.qmDialog .avue-crud__search .el-form-item__label {
  display: none;
}
.qmDialog .avue-crud__search .el-form-item__content {
  margin-left: 0 !important;
}
.qmDialog .avue-crud__search .reset-refresh {
  font-size: 12px;
}
.qmDialog .avue-crud__search .avue-form__menu .el-button {
  margin: 0 !important;
}